export const Access = {
  lineItemFilter: 'line-item-filter',
  broadcast: 'broadcast',
  companyDashboard: 'company-dashboard',
  searchWithoutCompanySelected: 'search-without-company-selected',
  subMediaType: 'sub-media-type',
  powerPointDownload: 'power-point-download',
  broadcastDigital: 'broadcast-digital-dashboard',
  hasAccessToMoreThanOneCompany: 'can-access-more-than-one-company',
  tritonAudioTopStation: 'triton-audio-top-stations',
  jelliTtwnPremiere: 'jelli-ttwn-premiere',
  companyJelliTtwnPremiere: 'company-jelli-ttwn-premiere',
  bundleDashboardReportTray: 'bundle-dashboard-report-tray',
  campaignDashboardReportTray: 'campaign-dashboard-report-tray',
  companyDashboardReportTray: 'company-dashboard-report-tray',
  viewScheduledReports: 'view-scheduled-reports',
  createScheduledReports: 'create-scheduled-reports',
  reportTrayAdditionalEmails: 'report-tray-added-functionality',
  reportTrayMultiTemplateSelect: 'report-tray-multi-template-select',
  canGetMaxAndMinImpressions: 'min-and-max-impressions',
  tradedeskInIhmDash: 'trade-desk-in-ihm-dash',
  streamingAudioBeeswaxInIhmDash: 'beeswax-in-ihm-dash', // Access to Beeswax Streaming Audio
  podcastBeeswaxInIhmDash: 'podcast-beeswax-in-ihm-dash',
  displayBeeswaxInIhmDash: 'display-beeswax-in-ihm-dash',
  prerollBeeswaxInIhmDash: 'preroll-beeswax-in-ihm-dash',
  ottBeeswaxInIhmDash: 'ott-beeswax-in-ihm-dash',
  broadcastRadioLdFlagEnableInIhmDash: 'stations-in-initiative-group-dashboard',
  broadcastJelliLdFlagEnableInIhmDash: 'jelli-in-initiative-group-dashboard',
  broadcastPremiereLdFlagEnableInIhmDash: 'premiere-in-initiative-group-dashboard',
  broadcastTtwnLdFlagEnableInIhmDash: 'ttwn-in-initiative-group-dashboard',
  salesDashboard: 'sales-dashboard-mvp',
  amazonReachAndFrequencyLdFlagEnableInIhmDash: 'amazon-reach-and-frequency-in-dash',
  broadcastSpotPerformanceWidgetLdFlagEnableInIhmDash: 'spot-performance-widget',
  vcrHeroMetric: 'vcr-hero-in-company-dash',
  storeVisitsHeroMetric: 'store-visits-hero-in-company-dash',
  landingPageHeroMetric: 'landing-page-hero-in-company-dash',
  ttwnJelliHeatMapInDashLdFlagEnableInIhmDash: 'ttwn-jelli-heat-map-in-dash',
  broadcastOrderedDemo: 'broadcast-ordered-demo',
  spotHeroMetricInDashLdFlagEnableInIhmDash: 'spot-hero-metric-in-dash',
} as const

export type Access = (typeof Access)[keyof typeof Access]

export interface AccessService {
  canAccess(access: Access): Promise<boolean>
}

export default AccessService
